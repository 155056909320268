@use '@digando/common-ui/scss/variables' as *;
@use '@digando/common-ui/scss/fonts' as *;

.socialMediaLinks {
  @include spacingBottom(7);

  @media all and (min-width: $tablet) {
    @include spacingBottom(0);
    grid-column: auto / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (min-width: $laptop) {
    justify-content: left;
  }

  .socialMediaItem {
    @include spacingLeft(5);

    &:first-of-type {
      @include spacingLeft(0);
    }

    svg path {
      transition: var(--text-hover-transition);
      fill: var(--white);
    }

    &:hover svg path {
      fill: var(--primary-color);
    }
  }

  .srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
